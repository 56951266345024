import './AdminHome.css'

const AdminHome = () => {
  return (
    <>
        <div>AdminHome</div>
    </>
  )
}



export default AdminHome
