import './TableBody.css'

const TableBody = (props) => {
  return (
    <>
     <tbody>
        {props.children} 
    </tbody>
    </>
  )
}

export default TableBody
