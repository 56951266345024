import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.css'
import './root.css'


import {AdminContainer, AdminHeader} from './sections/index.js'
import {AdminHome, Order, AdminProducts, Category, CreateNewCategory, CreateNewProduct,CreateProductItem, ProductItems, ProductItemView, OrderView, LogIn, VVSOrder, VVSOrderView, CategoryView, ProductsView} from './pages/index'
const App = () => {
    
    return (
        <>

            <Router>
                 <AdminHeader />
                <AdminContainer>
                   <Routes>
                    <Route path='/' element={<AdminHome/>}/>
                    <Route path='/log-in' element={<LogIn/>}/>
                    <Route path='/order' element={<Order/>}/>
                    <Route path='/vvs-order' element={<VVSOrder/>}/>
                    <Route path='/order/:orderNr' element={<OrderView/>}/>
                    <Route path='/vvs-order/:vvsOrderNr' element={<VVSOrderView/>}/>
                    <Route path='/products/items' element={<ProductItems/>}/>
                    <Route path='/products/items/view/:productItemNumber' element={<ProductItemView/>}/>
                    <Route path='/products/items/add-new-item' element={<CreateProductItem/>}/>
                    <Route path='/products' element={<AdminProducts/>}/>
                    <Route path='/products/:productId' element={<ProductsView/>}/>
                    <Route path='/products/add-new-product' element={<CreateNewProduct/>}/>
                    <Route path='/category' element={<Category/>}/>
                    <Route path='/category/:categoryId' element={<CategoryView/>}/>
                    <Route path='/category/add-new-category' element={<CreateNewCategory/>}/>
                    </Routes> 
                </AdminContainer>
            </Router>
        </>
    )
}

export default App